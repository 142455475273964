import styles from "./Footer.module.css";
import twitter from "../assets/twitter.svg";
import starkImage from "../assets/StarkNet-Icon.png";
const Footer = () => {
  const handleClick = () => {
    window.open("https://twitter.com/ScalarPool");
  };
  const handleClick2 = () => {
    window.open("https://starkware.co/starknet/");
  };
  return (
    <div className={styles.container}>
      <div></div>
      <div>
        <img
          src={twitter}
          className={styles.twitter}
          onClick={handleClick}
        ></img>
        <img
          src={starkImage}
          className={styles.stark}
          onClick={handleClick2}
        ></img>
      </div>
      <div></div>
    </div>
  );
};

export default Footer;
