import Image from "../assets/scalar.png";
import styles from "./Main.module.css";
const Main = () => {
  const handleClick = () => {
    window.open("https://twitter.com/211lp");
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.right}>
        <img className={styles.image} src={Image} onClick={handleClick} />
      </div>
      <div className={styles.text}>
        <b>SCALAR </b>
      </div>
    </div>
  );
};

export default Main;
